let area = [
    {
        "provinceType": 1,
        "name": "不限",
        "value": "",
    },
    {
        "provinceType": 1,
        "name": "北京市",
        "value": "北京市",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 1,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 1,
        "name": "上海市",
        "value": "上海市",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 1,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 1,
        "name": "天津市",
        "value": "天津市",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 1,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 1,
        "name": "重庆市",
        "value": "重庆市",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 1,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "河北省",
        "value": "河北省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "石家庄市",
                "value": "石家庄市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "唐山市",
                "value": "唐山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "秦皇岛市",
                "value": "秦皇岛市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "邯郸市",
                "value": "邯郸市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "邢台市",
                "value": "邢台市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "保定市",
                "value": "保定市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "张家口市",
                "value": "张家口市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "承德市",
                "value": "承德市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "沧州市",
                "value": "沧州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "廊坊市",
                "value": "廊坊市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "衡水市",
                "value": "衡水市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "辛集市",
                "value": "辛集市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "藁城市",
                "value": "藁城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "晋州市",
                "value": "晋州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "新乐市",
                "value": "新乐市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "鹿泉市",
                "value": "鹿泉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "遵化市",
                "value": "遵化市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "迁安市",
                "value": "迁安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "武安市",
                "value": "武安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "南宫市",
                "value": "南宫市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "沙河市",
                "value": "沙河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "涿州市",
                "value": "涿州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "定州市",
                "value": "定州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "安国市",
                "value": "安国市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高碑店市",
                "value": "高碑店市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "泊头市",
                "value": "泊头市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "任丘市",
                "value": "任丘市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "黄骅市",
                "value": "黄骅市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "河间市",
                "value": "河间市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "霸州市",
                "value": "霸州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "三河市",
                "value": "三河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "冀州市",
                "value": "冀州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "深州市",
                "value": "深州市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "山西省",
        "value": "山西省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "太原市",
                "value": "太原市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "大同市",
                "value": "大同市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "朔州市",
                "value": "朔州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "阳泉市",
                "value": "阳泉市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "长治市",
                "value": "长治市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "晋城市",
                "value": "晋城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "忻州市",
                "value": "忻州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "晋中市",
                "value": "晋中市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "临汾市",
                "value": "临汾市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "运城市",
                "value": "运城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "吕梁市",
                "value": "吕梁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "古交市",
                "value": "古交市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "潞城市",
                "value": "潞城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高平市",
                "value": "高平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "原平市",
                "value": "原平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "介休市",
                "value": "介休市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "侯马市",
                "value": "侯马市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "霍州市",
                "value": "霍州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "永济市",
                "value": "永济市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "河津市",
                "value": "河津市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "孝义市",
                "value": "孝义市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "汾阳市",
                "value": "汾阳市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "陕西省",
        "value": "陕西省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "西安市",
                "value": "西安市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "铜川市",
                "value": "铜川市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宝鸡市",
                "value": "宝鸡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "咸阳市",
                "value": "咸阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "渭南市",
                "value": "渭南市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "延安市",
                "value": "延安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "汉中市",
                "value": "汉中市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "榆林市",
                "value": "榆林市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "安康市",
                "value": "安康市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "商洛市",
                "value": "商洛市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "兴平市",
                "value": "兴平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "韩城市",
                "value": "韩城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "华阴市",
                "value": "华阴市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "山东省",
        "value": "山东省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "济南市",
                "value": "济南市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "青岛市",
                "value": "青岛市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "淄博市",
                "value": "淄博市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "枣庄市",
                "value": "枣庄市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "东营市",
                "value": "东营市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "烟台市",
                "value": "烟台市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "潍坊市",
                "value": "潍坊市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "济宁市",
                "value": "济宁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "泰安市",
                "value": "泰安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "威海市",
                "value": "威海市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "日照市",
                "value": "日照市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "莱芜市",
                "value": "莱芜市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "临沂市",
                "value": "临沂市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "德州市",
                "value": "德州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "聊城市",
                "value": "聊城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "滨州市",
                "value": "滨州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "菏泽市",
                "value": "菏泽市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "章丘市",
                "value": "章丘市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "胶南市",
                "value": "胶南市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "胶州市",
                "value": "胶州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "平度市",
                "value": "平度市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "莱西市",
                "value": "莱西市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "即墨市",
                "value": "即墨市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "滕州市",
                "value": "滕州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "龙口市",
                "value": "龙口市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "莱阳市",
                "value": "莱阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "莱州市",
                "value": "莱州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "招远市",
                "value": "招远市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "蓬莱市",
                "value": "蓬莱市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "栖霞市",
                "value": "栖霞市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海阳市",
                "value": "海阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "青州市",
                "value": "青州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "诸城市",
                "value": "诸城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "寿光市",
                "value": "寿光市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "安丘市",
                "value": "安丘市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高密市",
                "value": "高密市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "昌邑市",
                "value": "昌邑市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "曲阜市",
                "value": "曲阜市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兖州市",
                "value": "兖州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "邹城市",
                "value": "邹城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "新泰市",
                "value": "新泰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "肥城市",
                "value": "肥城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乳山市",
                "value": "乳山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "文登市",
                "value": "文登市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "荣成市",
                "value": "荣成市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乐陵市",
                "value": "乐陵市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "禹城市",
                "value": "禹城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临清市",
                "value": "临清市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "河南省",
        "value": "河南省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "郑州市",
                "value": "郑州市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "开封市",
                "value": "开封市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "洛阳市",
                "value": "洛阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "平顶山市",
                "value": "平顶山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "安阳市",
                "value": "安阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鹤壁市",
                "value": "鹤壁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "新乡市",
                "value": "新乡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "焦作市",
                "value": "焦作市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "濮阳市",
                "value": "濮阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "许昌市",
                "value": "许昌市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "漯河市",
                "value": "漯河市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "三门峡市",
                "value": "三门峡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "南阳市",
                "value": "南阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "商丘市",
                "value": "商丘市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "信阳市",
                "value": "信阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "周口市",
                "value": "周口市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "驻马店市",
                "value": "驻马店市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "巩义市",
                "value": "巩义市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "新郑市",
                "value": "新郑市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "新密市",
                "value": "新密市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "登封市",
                "value": "登封市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "荥阳市",
                "value": "荥阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "中牟县",
                "value": "中牟县",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "偃师市",
                "value": "偃师市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "汝州市",
                "value": "汝州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "舞钢市",
                "value": "舞钢市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "林州市",
                "value": "林州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "卫辉市",
                "value": "卫辉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "辉县市",
                "value": "辉县市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "沁阳市",
                "value": "沁阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "孟州市",
                "value": "孟州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "禹州市",
                "value": "禹州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "长葛市",
                "value": "长葛市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "义马市",
                "value": "义马市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "灵宝市",
                "value": "灵宝市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "邓州市",
                "value": "邓州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "永城市",
                "value": "永城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "项城市",
                "value": "项城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "济源市",
                "value": "济源市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "辽宁省",
        "value": "辽宁省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "沈阳市",
                "value": "沈阳市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "大连市",
                "value": "大连市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鞍山市",
                "value": "鞍山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "抚顺市",
                "value": "抚顺市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "本溪市",
                "value": "本溪市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "丹东市",
                "value": "丹东市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "锦州市",
                "value": "锦州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "营口市",
                "value": "营口市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "阜新市",
                "value": "阜新市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "辽阳市",
                "value": "辽阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "盘锦市",
                "value": "盘锦市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "铁岭市",
                "value": "铁岭市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "朝阳市",
                "value": "朝阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "葫芦岛市",
                "value": "葫芦岛市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "新民市",
                "value": "新民市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "瓦房店市",
                "value": "瓦房店市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "普兰店市",
                "value": "普兰店市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "庄河市",
                "value": "庄河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海城市",
                "value": "海城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "东港市",
                "value": "东港市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "凤城市",
                "value": "凤城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "凌海市",
                "value": "凌海市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "北镇市",
                "value": "北镇市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "大石桥市",
                "value": "大石桥市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "盖州市",
                "value": "盖州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "灯塔市",
                "value": "灯塔市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "调兵山市",
                "value": "调兵山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "开原市",
                "value": "开原市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "凌源市",
                "value": "凌源市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "北票市",
                "value": "北票市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兴城市",
                "value": "兴城市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "吉林省",
        "value": "吉林省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "长春市",
                "value": "长春市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "吉林市",
                "value": "吉林市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "四平市",
                "value": "四平市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "辽源市",
                "value": "辽源市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "通化市",
                "value": "通化市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "白山市",
                "value": "白山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "松原市",
                "value": "松原市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "白城市",
                "value": "白城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "延边朝鲜族自治州",
                "value": "延边朝鲜族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "九台市",
                "value": "九台市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "榆树市",
                "value": "榆树市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "德惠市",
                "value": "德惠市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "舒兰市",
                "value": "舒兰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "桦甸市",
                "value": "桦甸市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "蛟河市",
                "value": "蛟河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "磐石市",
                "value": "磐石市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "公主岭市",
                "value": "公主岭市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "双辽市",
                "value": "双辽市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "梅河口市",
                "value": "梅河口市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "集安市",
                "value": "集安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临江市",
                "value": "临江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "大安市",
                "value": "大安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "洮南市",
                "value": "洮南市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "延吉市",
                "value": "延吉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "图们市",
                "value": "图们市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "敦化市",
                "value": "敦化市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "龙井市",
                "value": "龙井市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "珲春市",
                "value": "珲春市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "和龙市",
                "value": "和龙市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "黑龙江省",
        "value": "黑龙江省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "哈尔滨市",
                "value": "哈尔滨市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "齐齐哈尔市",
                "value": "齐齐哈尔市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鹤岗市",
                "value": "鹤岗市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "双鸭山市",
                "value": "双鸭山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鸡西市",
                "value": "鸡西市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "大庆市",
                "value": "大庆市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "伊春市",
                "value": "伊春市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "牡丹江市",
                "value": "牡丹江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "佳木斯市",
                "value": "佳木斯市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "七台河市",
                "value": "七台河市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "黑河市",
                "value": "黑河市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "绥化市",
                "value": "绥化市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "大兴安岭地区",
                "value": "大兴安岭地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "尚志市",
                "value": "尚志市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "双城市",
                "value": "双城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "五常市",
                "value": "五常市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "讷河市",
                "value": "讷河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "密山市",
                "value": "密山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "虎林市",
                "value": "虎林市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "铁力市",
                "value": "铁力市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "绥芬河市",
                "value": "绥芬河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宁安市",
                "value": "宁安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海林市",
                "value": "海林市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "穆棱市",
                "value": "穆棱市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "同江市",
                "value": "同江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "富锦市",
                "value": "富锦市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "北安市",
                "value": "北安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "五大连池市",
                "value": "五大连池市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "安达市",
                "value": "安达市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "肇东市",
                "value": "肇东市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海伦市",
                "value": "海伦市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "江苏省",
        "value": "江苏省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "南京市",
                "value": "南京市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "无锡市",
                "value": "无锡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "徐州市",
                "value": "徐州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "常州市",
                "value": "常州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "苏州市",
                "value": "苏州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "南通市",
                "value": "南通市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "连云港市",
                "value": "连云港市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "淮安市",
                "value": "淮安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "盐城市",
                "value": "盐城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "扬州市",
                "value": "扬州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "镇江市",
                "value": "镇江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "泰州市",
                "value": "泰州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宿迁市",
                "value": "宿迁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "江阴市",
                "value": "江阴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宜兴市",
                "value": "宜兴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "邳州市",
                "value": "邳州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "新沂市",
                "value": "新沂市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "金坛市",
                "value": "金坛市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "溧阳市",
                "value": "溧阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "常熟市",
                "value": "常熟市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "张家港市",
                "value": "张家港市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "太仓市",
                "value": "太仓市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "昆山市",
                "value": "昆山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "吴江市",
                "value": "吴江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "如皋市",
                "value": "如皋市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "通州市",
                "value": "通州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海门市",
                "value": "海门市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "启东市",
                "value": "启东市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "东台市",
                "value": "东台市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "大丰市",
                "value": "大丰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高邮市",
                "value": "高邮市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "江都市",
                "value": "江都市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "仪征市",
                "value": "仪征市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "丹阳市",
                "value": "丹阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "扬中市",
                "value": "扬中市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "句容市",
                "value": "句容市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "泰兴市",
                "value": "泰兴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "姜堰市",
                "value": "姜堰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "靖江市",
                "value": "靖江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兴化市",
                "value": "兴化市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "浙江省",
        "value": "浙江省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "杭州市",
                "value": "杭州市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "宁波市",
                "value": "宁波市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "温州市",
                "value": "温州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "嘉兴市",
                "value": "嘉兴市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "湖州市",
                "value": "湖州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "绍兴市",
                "value": "绍兴市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "金华市",
                "value": "金华市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "衢州市",
                "value": "衢州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "舟山市",
                "value": "舟山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "台州市",
                "value": "台州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "丽水市",
                "value": "丽水市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "建德市",
                "value": "建德市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "富阳市",
                "value": "富阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临安市",
                "value": "临安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "余姚市",
                "value": "余姚市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "慈溪市",
                "value": "慈溪市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "奉化市",
                "value": "奉化市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "瑞安市",
                "value": "瑞安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乐清市",
                "value": "乐清市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "海宁市",
                "value": "海宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "平湖市",
                "value": "平湖市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "桐乡市",
                "value": "桐乡市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "诸暨市",
                "value": "诸暨市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "上虞市",
                "value": "上虞市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "嵊州市",
                "value": "嵊州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兰溪市",
                "value": "兰溪市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "义乌市",
                "value": "义乌市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "东阳市",
                "value": "东阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "永康市",
                "value": "永康市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "江山市",
                "value": "江山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临海市",
                "value": "临海市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "温岭市",
                "value": "温岭市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "龙泉市",
                "value": "龙泉市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "安徽省",
        "value": "安徽省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "合肥市",
                "value": "合肥市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "芜湖市",
                "value": "芜湖市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "蚌埠市",
                "value": "蚌埠市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "淮南市",
                "value": "淮南市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "马鞍山市",
                "value": "马鞍山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "淮北市",
                "value": "淮北市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "铜陵市",
                "value": "铜陵市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "安庆市",
                "value": "安庆市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "黄山市",
                "value": "黄山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "滁州市",
                "value": "滁州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "阜阳市",
                "value": "阜阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宿州市",
                "value": "宿州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "巢湖市",
                "value": "巢湖市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "六安市",
                "value": "六安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "亳州市",
                "value": "亳州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "池州市",
                "value": "池州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宣城市",
                "value": "宣城市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "桐城市",
                "value": "桐城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "天长市",
                "value": "天长市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "明光市",
                "value": "明光市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "界首市",
                "value": "界首市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宁国市",
                "value": "宁国市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "江西省",
        "value": "江西省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "南昌市",
                "value": "南昌市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "景德镇市",
                "value": "景德镇市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "萍乡市",
                "value": "萍乡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "九江市",
                "value": "九江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "新余市",
                "value": "新余市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鹰潭市",
                "value": "鹰潭市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "赣州市",
                "value": "赣州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "吉安市",
                "value": "吉安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宜春市",
                "value": "宜春市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "抚州市",
                "value": "抚州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "上饶市",
                "value": "上饶市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "乐平市",
                "value": "乐平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "瑞昌市",
                "value": "瑞昌市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "贵溪市",
                "value": "贵溪市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "瑞金市",
                "value": "瑞金市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "南康市",
                "value": "南康市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "井冈山市",
                "value": "井冈山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "丰城市",
                "value": "丰城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "樟树市",
                "value": "樟树市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高安市",
                "value": "高安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "德兴市",
                "value": "德兴市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "福建省",
        "value": "福建省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "福州市",
                "value": "福州市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "厦门市",
                "value": "厦门市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "莆田市",
                "value": "莆田市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "三明市",
                "value": "三明市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "泉州市",
                "value": "泉州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "漳州市",
                "value": "漳州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "南平市",
                "value": "南平市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "龙岩市",
                "value": "龙岩市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宁德市",
                "value": "宁德市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "福清市",
                "value": "福清市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "长乐市",
                "value": "长乐市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "永安市",
                "value": "永安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "石狮市",
                "value": "石狮市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "晋江市",
                "value": "晋江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "南安市",
                "value": "南安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "龙海市",
                "value": "龙海市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "邵武市",
                "value": "邵武市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "武夷山",
                "value": "武夷山",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "建瓯市",
                "value": "建瓯市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "漳平市",
                "value": "漳平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "建阳市",
                "value": "建阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "福安市",
                "value": "福安市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "福鼎市",
                "value": "福鼎市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "湖北省",
        "value": "湖北省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "武汉市",
                "value": "武汉市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "黄石市",
                "value": "黄石市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "十堰市",
                "value": "十堰市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "荆州市",
                "value": "荆州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宜昌市",
                "value": "宜昌市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "襄樊市",
                "value": "襄樊市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鄂州市",
                "value": "鄂州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "荆门市",
                "value": "荆门市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "孝感市",
                "value": "孝感市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "黄冈市",
                "value": "黄冈市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "咸宁市",
                "value": "咸宁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "随州市",
                "value": "随州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "恩施土家族苗族自治州",
                "value": "恩施土家族苗族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "大冶市",
                "value": "大冶市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "丹江口市",
                "value": "丹江口市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "洪湖市",
                "value": "洪湖市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "石首市",
                "value": "石首市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "松滋市",
                "value": "松滋市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宜都市",
                "value": "宜都市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "当阳市",
                "value": "当阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "枝江市",
                "value": "枝江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "老河口市",
                "value": "老河口市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "枣阳市",
                "value": "枣阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宜城市",
                "value": "宜城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "钟祥市",
                "value": "钟祥市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "应城市",
                "value": "应城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "安陆市",
                "value": "安陆市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "汉川市",
                "value": "汉川市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "麻城市",
                "value": "麻城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "武穴市",
                "value": "武穴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "赤壁市",
                "value": "赤壁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "广水市",
                "value": "广水市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "仙桃市",
                "value": "仙桃市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "天门市",
                "value": "天门市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "潜江市",
                "value": "潜江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "恩施市",
                "value": "恩施市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "利川市",
                "value": "利川市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "湖南省",
        "value": "湖南省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "长沙市",
                "value": "长沙市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "株洲市",
                "value": "株洲市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "湘潭市",
                "value": "湘潭市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "衡阳市",
                "value": "衡阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "邵阳市",
                "value": "邵阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "岳阳市",
                "value": "岳阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "常德市",
                "value": "常德市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "张家界市",
                "value": "张家界市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "益阳市",
                "value": "益阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "郴州市",
                "value": "郴州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "永州市",
                "value": "永州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "怀化市",
                "value": "怀化市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "娄底市",
                "value": "娄底市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "湘西土家族苗族自治州",
                "value": "湘西土家族苗族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "浏阳市",
                "value": "浏阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "醴陵市",
                "value": "醴陵市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "湘乡市",
                "value": "湘乡市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "韶山市",
                "value": "韶山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "耒阳市",
                "value": "耒阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "常宁市",
                "value": "常宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "武冈市",
                "value": "武冈市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临湘市",
                "value": "临湘市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "汨罗市",
                "value": "汨罗市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "津市市",
                "value": "津市市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "沅江市",
                "value": "沅江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "资兴市",
                "value": "资兴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "洪江市",
                "value": "洪江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "冷水江市",
                "value": "冷水江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "涟源市",
                "value": "涟源市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "吉首市",
                "value": "吉首市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "四川省",
        "value": "四川省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "成都市",
                "value": "成都市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "自贡市",
                "value": "自贡市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "攀枝花市",
                "value": "攀枝花市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "泸州市",
                "value": "泸州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "德阳市",
                "value": "德阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "绵阳市",
                "value": "绵阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "广元市",
                "value": "广元市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "遂宁市",
                "value": "遂宁市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "内江市",
                "value": "内江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "乐山市",
                "value": "乐山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "南充市",
                "value": "南充市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "眉山市",
                "value": "眉山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "宜宾市",
                "value": "宜宾市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "广安市",
                "value": "广安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "达州市",
                "value": "达州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "雅安市",
                "value": "雅安市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "巴中市",
                "value": "巴中市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "资阳市",
                "value": "资阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "阿坝藏族羌族自治州",
                "value": "阿坝藏族羌族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "甘孜藏族自治州",
                "value": "甘孜藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "凉山彝族自治州",
                "value": "凉山彝族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "都江堰市",
                "value": "都江堰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "彭州市",
                "value": "彭州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "邛崃市",
                "value": "邛崃市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "崇州市",
                "value": "崇州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "广汉市",
                "value": "广汉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "什邡市",
                "value": "什邡市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "绵竹市",
                "value": "绵竹市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "江油市",
                "value": "江油市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "峨眉山市",
                "value": "峨眉山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阆中市",
                "value": "阆中市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "华蓥市",
                "value": "华蓥市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "万源市",
                "value": "万源市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "简阳市",
                "value": "简阳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "西昌市",
                "value": "西昌市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "贵州省",
        "value": "贵州省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "贵阳市",
                "value": "贵阳市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "六盘水市",
                "value": "六盘水市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "遵义市",
                "value": "遵义市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "安顺市",
                "value": "安顺市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "铜仁地区",
                "value": "铜仁地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "毕节地区",
                "value": "毕节地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "黔西南布依族苗族自治州",
                "value": "黔西南布依族苗族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "黔东南苗族侗族自治州、",
                "value": "黔东南苗族侗族自治州、",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "黔南布依族苗族自治州",
                "value": "黔南布依族苗族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "清镇市",
                "value": "清镇市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "赤水市",
                "value": "赤水市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "仁怀市",
                "value": "仁怀市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "铜仁市",
                "value": "铜仁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "毕节市",
                "value": "毕节市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兴义市",
                "value": "兴义市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "凯里市",
                "value": "凯里市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "都匀市",
                "value": "都匀市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "福泉市",
                "value": "福泉市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "云南省",
        "value": "云南省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "昆明市",
                "value": "昆明市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "曲靖市",
                "value": "曲靖市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "玉溪市",
                "value": "玉溪市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "保山市",
                "value": "保山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "昭通市",
                "value": "昭通市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "丽江市",
                "value": "丽江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "普洱市",
                "value": "普洱市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "临沧市",
                "value": "临沧市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "文山壮族苗族自治州",
                "value": "文山壮族苗族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "红河哈尼族彝族自治州",
                "value": "红河哈尼族彝族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "西双版纳傣族自治州",
                "value": "西双版纳傣族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "楚雄彝族自治州",
                "value": "楚雄彝族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "大理白族自治州",
                "value": "大理白族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "德宏傣族景颇族自治州",
                "value": "德宏傣族景颇族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "怒江僳僳族自治州",
                "value": "怒江僳僳族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "迪庆藏族自治州",
                "value": "迪庆藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "安宁市",
                "value": "安宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宣威市",
                "value": "宣威市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "个旧市",
                "value": "个旧市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "开远市",
                "value": "开远市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "景洪市",
                "value": "景洪市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "楚雄市",
                "value": "楚雄市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "大理市",
                "value": "大理市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "潞西市",
                "value": "潞西市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "瑞丽市",
                "value": "瑞丽市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "广东省",
        "value": "广东省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "广州市",
                "value": "广州市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "深圳市",
                "value": "深圳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "珠海市",
                "value": "珠海市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "汕头市",
                "value": "汕头市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "韶关市",
                "value": "韶关市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "佛山市",
                "value": "佛山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "江门市",
                "value": "江门市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "湛江市",
                "value": "湛江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "茂名市",
                "value": "茂名市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "肇庆市",
                "value": "肇庆市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "惠州市",
                "value": "惠州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "梅州市",
                "value": "梅州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "汕尾市",
                "value": "汕尾市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "河源市",
                "value": "河源市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "阳江市",
                "value": "阳江市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "清远市",
                "value": "清远市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "东莞市",
                "value": "东莞市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "中山市",
                "value": "中山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "潮州市",
                "value": "潮州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "揭阳市",
                "value": "揭阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "云浮市",
                "value": "云浮市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "从化市",
                "value": "从化市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "增城市",
                "value": "增城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乐昌市",
                "value": "乐昌市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "南雄市",
                "value": "南雄市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "台山市",
                "value": "台山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "开平市",
                "value": "开平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "鹤山市",
                "value": "鹤山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "恩平市",
                "value": "恩平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "廉江市",
                "value": "廉江市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "雷州市",
                "value": "雷州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "吴川市",
                "value": "吴川市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高州市",
                "value": "高州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "化州市",
                "value": "化州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "信宜市",
                "value": "信宜市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "高要市",
                "value": "高要市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "四会市",
                "value": "四会市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "兴宁市",
                "value": "兴宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "陆丰市",
                "value": "陆丰市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阳春市",
                "value": "阳春市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "英德市",
                "value": "英德市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "连州市",
                "value": "连州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "普宁市",
                "value": "普宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "罗定市",
                "value": "罗定市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "海南省",
        "value": "海南省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "海口市",
                "value": "海口市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "三亚市",
                "value": "三亚市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "文昌市",
                "value": "文昌市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "琼海市",
                "value": "琼海市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "万宁市",
                "value": "万宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "五指山市",
                "value": "五指山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "东方市",
                "value": "东方市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "儋州市",
                "value": "儋州市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "甘肃省",
        "value": "甘肃省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "兰州市",
                "value": "兰州市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "金昌市",
                "value": "金昌市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "白银市",
                "value": "白银市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "天水市",
                "value": "天水市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "嘉峪关市",
                "value": "嘉峪关市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "武威市",
                "value": "武威市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "张掖市",
                "value": "张掖市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "平凉市",
                "value": "平凉市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "酒泉市",
                "value": "酒泉市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "庆阳市",
                "value": "庆阳市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "定西市",
                "value": "定西市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "陇南市",
                "value": "陇南市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "临夏回族自治州",
                "value": "临夏回族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "甘南藏族自治州",
                "value": "甘南藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "玉门市",
                "value": "玉门市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "敦煌市",
                "value": "敦煌市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "临夏市",
                "value": "临夏市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "合作市",
                "value": "合作市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "青海省",
        "value": "青海省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "西宁市",
                "value": "西宁市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "海东地区",
                "value": "海东地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "海北藏族自治州",
                "value": "海北藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "黄南藏族自治州",
                "value": "黄南藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "海南藏族自治州",
                "value": "海南藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "果洛藏族自治州",
                "value": "果洛藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "玉树藏族自治州",
                "value": "玉树藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "海西蒙古族藏族自治州",
                "value": "海西蒙古族藏族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "德令哈市",
                "value": "德令哈市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "格尔木市",
                "value": "格尔木市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 3,
        "name": "内蒙古自治区",
        "value": "内蒙古自治区",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "呼和浩特市",
                "value": "呼和浩特市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "包头市",
                "value": "包头市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "乌海市",
                "value": "乌海市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "赤峰市",
                "value": "赤峰市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "通辽市",
                "value": "通辽市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "鄂尔多斯市",
                "value": "鄂尔多斯市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "呼伦贝尔市",
                "value": "呼伦贝尔市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "巴彦淖尔市",
                "value": "巴彦淖尔市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "乌兰察布市",
                "value": "乌兰察布市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "锡林郭勒盟",
                "value": "锡林郭勒盟",
                "areaType": 6,
                "isCapital": false
            },
            {
                "name": "兴安盟",
                "value": "兴安盟",
                "areaType": 6,
                "isCapital": false
            },
            {
                "name": "阿拉善盟",
                "value": "阿拉善盟",
                "areaType": 6,
                "isCapital": false
            },
            {
                "name": "霍林郭勒市",
                "value": "霍林郭勒市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "满洲里市",
                "value": "满洲里市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "牙克石市",
                "value": "牙克石市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "扎兰屯市",
                "value": "扎兰屯市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "根河市",
                "value": "根河市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "额尔古纳市",
                "value": "额尔古纳市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "丰镇市",
                "value": "丰镇市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "锡林浩特市",
                "value": "锡林浩特市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "二连浩特市",
                "value": "二连浩特市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乌兰浩特市",
                "value": "乌兰浩特市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阿尔山市",
                "value": "阿尔山市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 3,
        "name": "新疆维吾尔自治区",
        "value": "新疆维吾尔自治区",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "乌鲁木齐市",
                "value": "乌鲁木齐市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "克拉玛依市",
                "value": "克拉玛依市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "吐鲁番地区",
                "value": "吐鲁番地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "哈密地区",
                "value": "哈密地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "和田地区",
                "value": "和田地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "阿克苏地区",
                "value": "阿克苏地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "喀什地区",
                "value": "喀什地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "塔城地区",
                "value": "塔城地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "阿勒泰地区",
                "value": "阿勒泰地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "克孜勒苏柯尔克孜自治州",
                "value": "克孜勒苏柯尔克孜自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "巴音郭楞蒙古自治州",
                "value": "巴音郭楞蒙古自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "昌吉回族自治州",
                "value": "昌吉回族自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "博尔塔拉蒙古自治州",
                "value": "博尔塔拉蒙古自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "伊犁哈萨克自治州",
                "value": "伊犁哈萨克自治州",
                "areaType": 5,
                "isCapital": false
            },
            {
                "name": "石河子市",
                "value": "石河子市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阿拉尔市",
                "value": "阿拉尔市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "图木舒克市",
                "value": "图木舒克市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "五家渠市",
                "value": "五家渠市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "吐鲁番市",
                "value": "吐鲁番市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "哈密市",
                "value": "哈密市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "和田市",
                "value": "和田市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阿克苏市",
                "value": "阿克苏市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "喀什市",
                "value": "喀什市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阿图什市",
                "value": "阿图什市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "库尔勒市",
                "value": "库尔勒市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "昌吉市",
                "value": "昌吉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阜康市",
                "value": "阜康市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "米泉市",
                "value": "米泉市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "博乐市",
                "value": "博乐市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "伊宁市",
                "value": "伊宁市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "奎屯市",
                "value": "奎屯市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "塔城市",
                "value": "塔城市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "乌苏市",
                "value": "乌苏市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "阿勒泰市",
                "value": "阿勒泰市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 3,
        "name": "西藏自治区",
        "value": "西藏自治区",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "拉萨市",
                "value": "拉萨市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "那曲地区",
                "value": "那曲地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "昌都地区",
                "value": "昌都地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "山南地区",
                "value": "山南地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "日喀则地区",
                "value": "日喀则地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "阿里地区",
                "value": "阿里地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "林芝地区",
                "value": "林芝地区",
                "areaType": 4,
                "isCapital": false
            },
            {
                "name": "日喀则市",
                "value": "日喀则市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 3,
        "name": "广西壮族自治区",
        "value": "广西壮族自治区",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "南宁市",
                "value": "南宁市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "柳州市",
                "value": "柳州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "桂林市",
                "value": "桂林市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "梧州市",
                "value": "梧州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "北海市",
                "value": "北海市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "防城港市",
                "value": "防城港市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "钦州市",
                "value": "钦州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "贵港市",
                "value": "贵港市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "玉林市",
                "value": "玉林市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "百色市",
                "value": "百色市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "贺州市",
                "value": "贺州市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "河池市",
                "value": "河池市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "来宾市",
                "value": "来宾市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "崇左市",
                "value": "崇左市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "岑溪市",
                "value": "岑溪市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "东兴市",
                "value": "东兴市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "桂平市",
                "value": "桂平市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "北流市",
                "value": "北流市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "宜州市",
                "value": "宜州市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "合山市",
                "value": "合山市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "凭祥市",
                "value": "凭祥市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "宁夏回族自治区",
        "value": "宁夏回族自治区",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "银川市",
                "value": "银川市",
                "areaType": 2,
                "isCapital": true
            },
            {
                "name": "石嘴山市",
                "value": "石嘴山市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "吴忠市",
                "value": "吴忠市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "固原市",
                "value": "固原市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "中卫市",
                "value": "中卫市",
                "areaType": 2,
                "isCapital": false
            },
            {
                "name": "灵武市",
                "value": "灵武市",
                "areaType": 3,
                "isCapital": false
            },
            {
                "name": "青铜峡市",
                "value": "青铜峡市",
                "areaType": 3,
                "isCapital": false
            }
        ]
    },
    {
        "provinceType": 4,
        "name": "香港",
        "value": "香港",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 7,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 4,
        "name": "澳门",
        "value": "澳门",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 7,
                "isCapital": true
            }
        ]
    },
    {
        "provinceType": 2,
        "name": "台湾省",
        "value": "台湾省",
        "city": [
            {
                "name": "全部",
                "value": "",
                "areaType": 7,
                "isCapital": true
            }
        ]
    },
]
export default area;
import request from '@/utils/request'

/**
 * 上传图片
 * @param {*} resourcesData 
 * @param {*} path 
 * @param {*} originalName 
 * @param {*} size 
 * @param {*} fileExtension 
 * @returns 
 */
export function upFiles(resourcesData, path, originalName, size, fileExtension) {
  return request.post("api/upFiles", {
    resourcesData: resourcesData,
    path: path,
    originalName: originalName,
    size: size,
    fileExtension: fileExtension || '.jpg'  // 默认格式jpg
  });
}

/**
 * token
 * @param {*} query
 * @returns
 */

// export const upTokens = (data) => {
//   return request({
//     url: 'tool/upTokens',
//     method: "post",
//     data
//   })
// }

// // 刷新token
// export const getNewTokens = (data) => {
//   return request({
//     url: 'api/wares/tokenRefreshes',
//     method: "post",
//     data
//   })
// }

// 获取字典数据
export const getDict = (params) => {
  return request({
    url: '/api/source/sourceList',
    method: "get",
    params
  })
}

// 获取树形结构
export const sourceTree = (params) => {
  return request({
    url: '/api/source/sourceTree',
    method: "get",
    params
  })
}


